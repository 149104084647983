<template>
  <div class="score">
            <div class="top-box">
              <div class="top-title">分数查询</div>
              <div class="btn-box">
                <div class="btn" @click="handleSearchClick"><i class="el-icon-search"></i> 查询</div>
                <div class="btn" @click="handlerShowClick">{{showSearch?'收起':'展开'}}</div>
              </div>
            </div>
            <el-row class="form-group" v-if="showSearch">
              <el-col :span="6" class="form-item">
                  <div class="label">省份</div>
                  <div class="input-box">
                    <el-select class="form-control" v-model="province"
                    v-loading="provinceListLoading"
                    element-loading-spinner="el-icon-loading">
                      <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in provinceList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">院校</div>
                  <div class="input-box">
                    <el-input v-model="school" placeholder="请输入院校" class="form-control"></el-input>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">专业</div>
                  <div class="input-box">
                    <el-select class="form-control" v-model="major"
                    v-loading="majorListLoading"
                    element-loading-spinner="el-icon-loading">
                      <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in majorList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                      </el-select>
                  </div>
              </el-col>
              <el-col :span="5" class="form-item">
                  <div class="label">科类</div>
                  <div class="input-box">
                    <el-select class="form-control" v-model="subject"
                    v-loading="subjectListLoading"
                    element-loading-spinner="el-icon-loading">
                      <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in subjectList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
              </el-col>
              <el-col :span="1" class="form-item">
                <div class="action-link" @click="handlerFormCollapse()">{{formCollapse?'收起':'展开'}}</div>
              </el-col>
              <div v-if="formCollapse">
                <el-col :span="6" class="form-item">
                    <div class="label">地区</div>
                    <div class="input-box">
                       <el-input v-model="region" placeholder="请输入地区" class="form-control"></el-input>
                    </div>
                </el-col>
                <el-col :span="6" class="form-item">
                    <div class="label">批次</div>
                    <div class="input-box">
                      <el-select class="form-control" v-model="batch"
                      v-loading="batchListLoading"
                      element-loading-spinner="el-icon-loading">
                        <el-option key="0" label="请选择..." value=""></el-option>
                        <el-option
                          v-for="item in batchList"
                          :key="item.key"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </div>
                </el-col>
                <el-col :span="6" class="form-item">
                    <div class="label">备注</div>
                    <div class="input-box">
                      <el-input v-model="remark" placeholder="请输入备注" class="form-control"></el-input>
                    </div>
                </el-col>
                <el-col :span="6" class="form-item">
                    <div class="label">提档分</div>
                    <div class="input-box">
                      <el-input v-model="scoreBegin" class="form-control"></el-input>
                      <div class="split-line">—</div>
                      <el-input v-model="scoreEnd" class="form-control"></el-input>
                    </div>
                </el-col>
                <el-col :span="24" class="form-item">
                    <div class="label">地区</div>
                    <div class="input-box" v-loading="yearListLoading"
                      element-loading-spinner="el-icon-loading">
                      <el-checkbox-group v-model="checkedYears">
                        <el-checkbox v-for="item in yearList"
                          :key="item"
                          :label="item"></el-checkbox>
                      </el-checkbox-group>
                    </div>
                </el-col>
              </div>
              
            </el-row>
            <!--  -->
            <div class="table-container-body">
              <el-table :data="tableData" height="500" stripe class="table-body" 
              :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
              v-loading="tableLoading">
                <el-table-column prop="name" label="院校名称" width="140">
                </el-table-column>
                <el-table-column prop="major" label="专业名称" width="200">
                </el-table-column>
                <el-table-column prop="type" label="院校类型" width="100">
                </el-table-column>
                <el-table-column prop="batch" label="批次" width="65">
                </el-table-column>
                <el-table-column prop="subject" label="科类" width="60">
                </el-table-column>
                <el-table-column prop="feature" label="特色">
                  <template slot-scope="scope">
                    <span>{{scope.row.feature.join(",")}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="region" label="地区" width="65">
                </el-table-column>
                <el-table-column prop="plan2020" label="计划数2020" width="65">
                </el-table-column>
                <el-table-column prop="score2020" label="提档分2020" width="65">
                </el-table-column>
                <el-table-column prop="rank2020" label="排位2020" width="55">
                </el-table-column>
                <el-table-column prop="difference2020" label="线差  2020" width="55">
                </el-table-column>
                <el-table-column prop="remark" label="备注" width="300">
                </el-table-column>
              </el-table>
            </div>
            <div class="table-container-footer">
              <el-pagination
                layout="total, prev, pager, next, sizes, jumper"
                :page-sizes="[10, 50, 100, 500]"
                :page-size="pageSize"
                :total="pageTotal"
                :current-page="pageNo"
                @current-change="pageChange"
                @size-change="sizeChange"
              >
              </el-pagination>
            </div>
            <!--  -->
          </div>
</template>
<script>
export default {
  name: "score",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      pageNo: 1,
      pageSize:10,
      pageTotal:0,
      showSearch:false,
      formCollapse:false,
      provinceListLoading:false,
      majorListLoading:false,
      subjectListLoading:false,
      batchListLoading:false,
      yearListLoading:false,
      tableLoading:false,
      province:"",
      school:"",
      major:"",
      subject:"",
      region:"",
      batch:"",
      remark:"",
      scoreBegin:"",
      scoreEnd:"",
      checkedYears:[],
      provinceList:[],
      majorList:[],
      subjectList:[],
      batchList:[],
      regionList:[],
      yearList:[],
      tableData: [],
    };
  },
  methods: {
    initSelectData(){
      this.provinceListLoading = true;
      //获取省份
      this.$http.post(this.$apis.getProvinceOrCityList+"?type=province").then((res) => {
        this.provinceListLoading = false;
        if (res.data) {
          this.$store.commit("setProvinceList", res.data);
          this.provinceList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      this.majorListLoading = true;
      //获取专业
      this.$http.post(this.$apis.getSubjectList).then((res) => {
        this.majorListLoading = false;
        if (res.data) {
          this.majorList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      this.subjectListLoading= true;
      //获取科类
      this.$http.post(this.$apis.getCourseList).then((res) => {
        this.subjectListLoading = false;
        if (res.data) {
          this.subjectList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      this.batchListLoading = true;
      //获取批次
      this.$http.post(this.$apis.getDictionary+"?type=batch").then(r=>{
        this.batchListLoading = false;
        if(r.data){
          this.batchList = r.data.map(m=>{
            return {
              key:m.id,
              label:m.name,
              value:m.code
            }
          });
        }
      });
      this.yearListLoading = true;
      //获取年度
      this.$http.post(this.$apis.getYears).then((res) => {
        this.yearListLoading= false;
        if (res.data) {
          this.yearList = res.data.map(r=>{ return r });
        }
      });
    },
    initTableData(){
      this.tableLoading = true;
      var data={
          batch: this.batch,
          courseTypeCode: this.subject,
          currentPage: this.pageNo,
          note: this.remark,
          pageSize: this.pageSize,
          province: this.province,
          region: this.region,
          schoolName: this.school,
          //todo 缺少专业代码
          subjectName: this.major,
          upgradeScoreEnd: this.scoreBegin,
          upgradeScoreStart: this.scoreEnd,
          //year: "<integer>"
      }
      //获取分页表格数据
      this.$http.post(this.$apis.getScore,data).then((res) => {
        this.tableLoading = false;
        if (res.data) {
          this.tableData = res.data.list.map(r=>{
            //todo
            return {
              name: "清华大学",
              major:"文科实验班类(通用基础类)",
              type:"综合类",
              batch:"常规批",
              subject:"不限",
              feature:[
                "211",
                "985",
                "双一流"
              ],
              region:"北京市",
              plan2020:"1",
              score2020:"706",
              rank2020:"5",
              difference2020:"",
              remark:"(通用基础类)(经济与金融等专业)(数学与应用数学)"
            }
          });
          this.tableTotal = res.data.total;
        }else{
          this.tableData = [
            {
              name: "清华大学",
              major:"文科实验班类(通用基础类)",
              type:"综合类",
              batch:"常规批",
              subject:"不限",
              feature:[
                "211",
                "985",
                "双一流"
              ],
              region:"北京市",
              plan2020:"1",
              score2020:"706",
              rank2020:"5",
              difference2020:"",
              remark:"(通用基础类)(经济与金融等专业)(数学与应用数学)"
            },
            {
              name: "清华大学",
              major:"文科实验班类(通用基础类)",
              type:"综合类",
              batch:"常规批",
              subject:"不限",
              feature:[
                "211",
                "985",
                "双一流"
              ],
              region:"北京市",
              plan2020:"1",
              score2020:"706",
              rank2020:"5",
              difference2020:"",
              remark:"(通用基础类)(经济与金融等专业)(数学与应用数学)"
            }
          ];
        }
      });
    },
    handleSearchClick(){
      this.pageNo = 1;
      this.initTableData();
    },
    handlerShowClick(){
      this.showSearch = !this.showSearch;
    },
    handlerFormCollapse(){
      this.formCollapse = !this.formCollapse;
    },
    pageChange(val) {
      this.pageNo = val;
      this.initTableData();
    },
    sizeChange(val){
      this.pageSize = val;
      this.initTableData();
    }
  },
  mounted(){
    this.initSelectData();
    this.initTableData();
  },
};
</script>

<style lang="less" scoped>
.score {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 5px;
  border: 1px solid rgba(230, 230, 230, 100);

  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid rgba(230, 230, 230, 100);

    .top-title {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      position: relative;
    }
    .top-title::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 27px;
      width: 3px;
      background-color: #ff450b;
    }

    .btn-box {
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  .form-group{
      padding: 0px 20px;
    .form-item {
      display: flex;
      align-items: center;
      height: 55px;
      padding: 15px 0 0 0;
      .label {
        display: flex;
        justify-content: flex-end;
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 70px;
        padding-right:10px;
      }
      .input-box {
          display: flex;
          width: calc(100% - 100px);
          .split-line{
            display:flex;
            align-items: center;
            padding: 0 5px;
          }
      }
      .required-label{
            color: rgb(255, 0, 0);
      }
      .action-link{
          font-size: 14px;
          cursor:pointer;
          color: #ff450b;
      }
    }
  }
  .form-control{
      width: 100%;
  }

  .table-container-body {
    padding: 15px 20px;
    .table-body{
      width: 100%;
      overflow: auto;
      border: 1px solid #e6e6e6;
    }
    .logo{
      height: 48px;
      width: 48px;
    }
    .label{
      display: inline-block;
      border: 1px solid #646464;
      font-size: 12px;
      margin-right: 2px;
      border-radius: 4px;
      padding: 0 5px;
      line-height: 14px;
    }
    .table-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .table-btn {
        cursor: pointer;
        width: 60px;
        line-height: 28px;
        border-radius: 14px;
        color: rgba(121, 160, 241, 100);
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(121, 160, 241, 100);
      }
    }
  }
  .table-container-footer{
    padding: 20px;
  }
  
}

</style>